/* External dependencies */
import { Div, Container } from 'atomize';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

/* Local dependencies */
import AnimatedLogo from '../../../components/animated-logo';
import ChangePassword from '../../../components/login/change-password';
import Layout from '../../../components/layout';
import formatMessage from '../../../intl/formatMessage';
import '../login.scss';

export default function Login() {
  const title = formatMessage('changePasswordTitle');
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = useStaticQuery(query);

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', title)}</title>
      </Helmet>
      <Container>
        <Div className="loginPage">
          <AnimatedLogo />
          <ChangePassword />
        </Div>
      </Container>
    </Layout>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
