/* External dependencies */
import { Col, Div, Row, Text } from 'atomize';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React from 'react';
import * as ReactRedux from 'react-redux';

/* Local dependencies */
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import {
  changePassword,
  confirmPassword,
} from '../../../components/login/redux/actions';
import formatMessage from '../../../intl/formatMessage';
import { RootState } from '../../../redux/store';
import FormField from '../../form/FormField';
import { formStyle, wrapStyle } from '../../form/FormStyles';

export default function ChangePassword() {
  const dispatch = ReactRedux.useDispatch();
  const [newPassword, setNewPassword] = React.useState('');
  const { error, loading, username, verificationCode } = ReactRedux.useSelector(
    (store: RootState) => store.login,
  );

  const validateNewPassword = (password) => {
    setNewPassword(password.trim());
  };

  const onSubmit = (e) => {
    e.preventDefault();

    verificationCode
      ? dispatch(confirmPassword(newPassword, username))
      : dispatch(changePassword(newPassword, username));
  };

  const isInvalid = !username || !newPassword;

  const labelPhone = formatMessage('phone');
  const labelNewPassword = formatMessage('newPassword');
  const phonePlaceholder = formatMessage('phonePlaceholder');
  const newPasswordPlaceholder = formatMessage('newPasswordPlaceholder');
  const changePasswordDescription = formatMessage('changePasswordDescription');

  return (
    <Div {...wrapStyle}>
      <form onSubmit={onSubmit} style={formStyle}>
        <Row m="0">
          <Col
            border={{ b: '1px solid' }}
            textAlign="left"
            borderColor="basic_border_color"
          >
            <Text tag="h1" textSize="18px" textWeight="bold" m="1rem">
              {<FormattedMessage id="changePasswordTitle" />}
            </Text>
          </Col>
        </Row>
        <Row p="1rem 1rem 0 1rem" m="0 0 1rem 0">
          <FormField
            disabled={true}
            label={labelPhone}
            inputValue={username}
            inputType="tel"
            inputName="username"
            placeholder={phonePlaceholder}
            required={true}
          />
          <FormField
            colSizeLabel="12"
            disabled={loading}
            fieldDescription={changePasswordDescription}
            label={labelNewPassword}
            inputValue={newPassword}
            inputType="password"
            inputName="password"
            onChange={(e) => validateNewPassword(e.target.value)}
            placeholder={newPasswordPlaceholder}
            required={true}
          />
          {error && (
            <Col>
              <Text textColor="red">{error.message}</Text>
            </Col>
          )}
        </Row>
        <Row
          m="0"
          border={{ t: '1px solid' }}
          borderColor="basic_border_color"
          p="1rem"
        >
          <Col d="flex" justify="flex-end">
            <PrimaryButton disabled={isInvalid || loading} loading={loading}>
              {<FormattedMessage id="saveAndSubmit" />}
            </PrimaryButton>
          </Col>
        </Row>
      </form>
    </Div>
  );
}
